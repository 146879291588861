import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main1',
    component: () => import('../views/Main.vue') ,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',    
    component: () => import('../views/About.vue')
  },
  {
    path: '/main',
    name: 'Main',    
    component: () => import('../views/Main.vue') ,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'Tasks',    
    component: () => import('../views/Tasks.vue') ,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',    
    component: () => import('../views/Profile.vue') ,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/balance',
    name: 'Balance',    
    component: () => import('../views/Balance.vue') ,
    props: true,
    meta: {
      requiresAuth: true
    }
  
},
{
  path: '/Testtask',
  name: 'Testtask',    
  component: () => import('../views/Testtask.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }

},

{
  path: '/Ka_main',
  name: 'Ka_main',    
  component: () => import('../views/Ka_main.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }

},

{
  path: '/Phototask',
  name: 'Phototask',    
  component: () => import('../views/Phototask.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }

},

{
  path: '/Phototask_dmx',
  name: 'Phototask_dmx',    
  component: () => import('../views/Phototask_dmx.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }

},{
  path: '/News_sel',
  name: 'News_sel',    
  component: () => import('../views/News_sel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
}


,{
  path: '/News',
  name: 'News',    
  component: () => import('../views/News.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
}

,{
  path: '/News_pg',
  name: 'News_pg',    
  component: () => import('../views/News_pg.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
}
,{
  path: '/News_arch',
  name: 'News_arch',    
  component: () => import('../views/News_arch.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
}



,

{
  path: '/Withdraw_sel',
  name: 'Withdraw_sel',    
  component: () => import('../views/Withdraw_sel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }

},

{
  path: '/Withdraw_silpo',
  name: 'Withdraw_silpo',    
  component: () => import('../views/Withdraw_silpo.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},


{
  path: '/Withdraw_eva',
  name: 'Withdraw_eva',    
  component: () => import('../views/Withdraw_eva.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},



{
  path: '/Withdraw_rozetka',
  name: 'Withdraw_rozetka',    
  component: () => import('../views/Withdraw_rozetka.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},



{
  path: '/Withdraw_fishka',
  name: 'Withdraw_fishka',    
  component: () => import('../views/Withdraw_fishka.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},




{
  path: '/Withdraw_mob',
  name: 'Withdraw_mob',    
  component: () => import('../views/Withdraw_mob.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},


{
  path: '/Withdraw_wog',
  name: 'Withdraw_wog',    
  component: () => import('../views/Withdraw_wog.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},


{
  path: '/Withdraw_atb',
  name: 'Withdraw_atb',    
  component: () => import('../views/Withdraw_atb.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},


{
  path: '/Withdraw_epi',
  name: 'Withdraw_epi',    
  component: () => import('../views/Withdraw_epi.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},


{
  path: '/Withdraw_novus',
  name: 'Withdraw_novus',    
  component: () => import('../views/Withdraw_novus.vue') , 
  props: true,
  meta: {
    requiresAuth: true
  }

},
  /*
{
  path: '/asssel',
  name: 'Asssel',    
  component: () => import('../views/Asssel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/asssel_temp',
  name: 'Asssel_temp',    
  component: () => import('../views/Asssel_temp.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

*/
{
  path: '/instr',
  name: 'Instr',    
  component: () => import('../views/Instr.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/vazhl_sel',
  name: 'Vazhl_sel',    
  component: () => import('../views/Vazhl_sel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/vazhl_1',
  name: 'Vazhl_1',    
  component: () => import('../views/Vazhl_1.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/vazhl_2',
  name: 'Vazhl_2',    
  component: () => import('../views/Vazhl_2.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/vazhl_3',
  name: 'Vazhl_3',    
  component: () => import('../views/Vazhl_3.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/vazhl_4',
  name: 'Vazhl_4',    
  component: () => import('../views/Vazhl_4.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/vazhl_5',
  name: 'Vazhl_5',    
  component: () => import('../views/Vazhl_5.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/vazhl_6',
  name: 'Vazhl_6',    
  component: () => import('../views/Vazhl_6.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},




  
{
  path: '/asview',
  name: 'Asview',    
  component: () => import('../views/Asview.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/TPQ',
  name: 'TPQ',    
  component: () => import('../views/TPQ.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/Info_sel',
  name: 'Info_sel',    
  component: () => import('../views/Info_sel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_sel',
  name: 'Info_Brands_sel',    
  component: () => import('../views/Info_Brands_sel.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_Davidoff',
  name: 'Info_Brands_Davidoff',    
  component: () => import('../views/Info_Brands_Davidoff.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_Davidoff_PL',
  name: 'Info_Brands_Davidoff_PL',    
  component: () => import('../views/Info_Brands_Davidoff_PL.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_Davidoff_SL',
  name: 'Info_Brands_Davidoff_SL',    
  component: () => import('../views/Info_Brands_Davidoff_SL.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_Davidoff_reach',
  name: 'Info_Brands_Davidoff_reach',    
  component: () => import('../views/Info_Brands_Davidoff_reach.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_Davidoff_40',
  name: 'Info_Brands_Davidoff_40',    
  component: () => import('../views/Info_Brands_Davidoff_40.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_PS',
  name: 'Info_Brands_PS',    
  component: () => import('../views/Info_Brands_PS.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_PS_BIG',
  name: 'Info_Brands_PS_BIG',    
  component: () => import('../views/Info_Brands_PS_BIG.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/Info_Brands_PS_KS',
  name: 'Info_Brands_PS_KS',    
  component: () => import('../views/Info_Brands_PS_KS.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/Info_Brands_PS_COMPACT',
  name: 'Info_Brands_PS_COMPACT',    
  component: () => import('../views/Info_Brands_PS_COMPACT.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_Jade',
  name: 'Info_Brands_Jade',    
  component: () => import('../views/Info_Brands_Jade.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_Jade_SSL',
  name: 'Info_Brands_Jade_SSL',    
  component: () => import('../views/Info_Brands_Jade_SSL.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_Jade_COMPACT',
  name: 'Info_Brands_Jade_COMPACT',    
  component: () => import('../views/Info_Brands_Jade_COMPACT.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_West',
  name: 'Info_Brands_West',    
  component: () => import('../views/Info_Brands_West.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_West_KS',
  name: 'Info_Brands_West_KS',    
  component: () => import('../views/Info_Brands_West_KS.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_West_XL',
  name: 'Info_Brands_West_XL',    
  component: () => import('../views/Info_Brands_West_XL.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_West_UP',
  name: 'Info_Brands_West_UP',    
  component: () => import('../views/Info_Brands_West_UP.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/Info_Brands_ImperialClassic',
  name: 'Info_Brands_ImperialClassic',    
  component: () => import('../views/Info_Brands_ImperialClassic.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_IC_Compact',
  name: 'Info_Brands_IC_Compact',    
  component: () => import('../views/Info_Brands_IC_Compact.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/Info_Brands_IC_25',
  name: 'Info_Brands_IC_25',    
  component: () => import('../views/Info_Brands_IC_25.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},



{
  path: '/Info_Brands_Prima',
  name: 'Info_Brands_Prima',    
  component: () => import('../views/Info_Brands_Prima.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_Prima_lux',
  name: 'Info_Brands_Prima_lux',    
  component: () => import('../views/Info_Brands_Prima_lux.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Info_Brands_Prima_sribna',
  name: 'Info_Brands_Prima_sribna',    
  component: () => import('../views/Info_Brands_Prima_sribna.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},


{
  path: '/C_info',
  name: 'C_info',    
  component: () => import('../views/C_info.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

{
  path: '/Rate',
  name: 'Rate',    
  component: () => import('../views/Rate.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},

/*
{
  path: '/LPR_STAT',
  name: 'LPR_STAT',    
  component: () => import('../views/LPR_STAT.vue') ,
  props: true,
  meta: {
    requiresAuth: true
  }
},*/



  {
    path: '/login',
    name: 'Login',    
    component: () => import('../views/Login.vue')
  },
  
  {
    path: '/get_gps',
    name: 'get_gps',    
    component: () => import('../views/get_gps.vue'),
    meta: {
      requiresAuth: false
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (store.getters.isLoggedIn) {
      console.log('logged_in_next');
      console.log('PROFSET:'+store.getters.prof_set);
      next()
      return
      // if(store.getters.prof_set=='1')
      // {
      //   next()
      //   return
      // }
      // else{
      //   console.log('prof_not_set');
      //   next('/Profile')
      //   return

      // }
      
    }
    console.log('not_logged_in');
    next('/login')
  } else {
    next()
  }
})

export default router
